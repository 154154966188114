@import url("https://fonts.cdnfonts.com/css/sequel");

@media screen and (max-width: 1200px) {
    html {
        font-size: 100%;
    }
}

@media screen and (max-width: 1100px) {
    html {
        font-size: 95%;
    }
}

@media screen and (max-width: 1000px) {
    html {
        font-size: 90%;
    }
}

@media screen and (max-width: 900px) {
    html {
        font-size: 85%;
    }
}

@media screen and (max-width: 800px) {
    html {
        font-size: 80%;
    }
}

@media screen and (max-width: 700px) {
    html {
        font-size: 75%;
    }
}

@media screen and (max-width: 600px) {
    html {
        font-size: 62%;
    }
}

@media screen and (max-width: 500px) {
    body {
        -webkit-text-size-adjust: none;
    }
    html {
        font-size: 55%;
    }
}

@media screen and (max-width: 400px) {
    body {
        -webkit-text-size-adjust: none;
    }
    html {
        font-size: 45%;
    }
}

@media screen and (max-width: 300px) {
    body {
        -webkit-text-size-adjust: none;
    }
    html {
        font-size: 45%;
    }
}

@media screen and (max-width: 200px) {
    html {
        font-size: 40%;
    }
}

@media screen and (max-width: 100px) {
    html {
        font-size: 30%;
    }
}

h1 {
    font-family: "Sequel", sans-serif;
    font-size: 3.5rem;
    font-weight: 300;
    color: white;
}

h2 {
    font-family: "Sequel", sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    color: black;
}

h4 {
    font-family: "Sequel", sans-serif;
    font-size: 1.2rem;
    font-weight: 100;
    color: white;
}

p {
    font-family: "Sequel", sans-serif;
    font-size: 0.9rem;
    font-weight: 100;
    color: white;
}

a:hover {
    color: orange;
}

.pdfImage {
    width: 20%;
}

.pdf-container {
    margin: 4vw 0vw 4vw 0vw;
    position: relative;
    left: 15%;
    width: 70%;
    height: 70vh;
    background-color: #e4e4e4;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background {
    display: block;
    height: 80vh;
    top: 0;
    background-image: url("./res/LandingPage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.titleText {
    display: inline-block;
    margin: 55vh 0vw 0vh 5vw;
}

.titleButton {
    left: 0px;
    display: block;
    margin: 3vh 0vw 0vh 5vw;

    width: auto;
    height: 5vh;
}

.center {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* Tools */

.tools {
    width: 90vw;
    margin-top: 5vh;
    margin-bottom: 3vh;
    margin-left: 5vw;
}

.link {
    text-decoration: none;
}

.toolsHeader {
    margin-top: 5vh;
    margin-left: 5vw;
    position: relative;
    width: 90%;
    height: 100%;
    border-bottom: 2px solid black;
}

/* Navbar */

.navbar {
    width: 100vw;
    height: 8vh;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    position: absolute;
    display: inline-flex;
    align-items: center;
}

.navbarLogo {
    margin-right: 4vw;
    margin-left: 5vw;
    width: 7vh;
}

.navbarLogo:hover {
    filter: brightness(1.3);
}

.navbarText {
    margin-right: 4vw;
    cursor: pointer;

    font-family: "Sequel", sans-serif;
    font-size: 1.2rem;
    font-weight: 100;
    color: white;

    text-decoration: none;
}

.navbarButton {
    margin-left: 2vw;
    height: 4.5vh;
    display: inline;
}

.toolsDropdown {
    position: absolute;
    transform: translateX(-1.5vw);
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
    display: block;
    margin: auto;
    top: 0vh;
    padding-top: 8vh;
    padding-bottom: 0.5vh;
    left: inherit;
}

.toolsDropdownItem {
    display: block;
    padding: 1vh 1vw 1vh 1vw;

    width: 100%;
    margin: auto;

    cursor: pointer;
}

.toolsDropdownItem:hover {
    color: orange;
}

.toolsDropdownText {
    font-size: 0.9rem;
    color: white;
    font-family: "Sequel", sans-serif;
    word-wrap: break-word;
}

.userImage {
    top: 0;
    margin-left: 1.5vw;
    height: 6vh;
    border-radius: 50%;
    transform: translate(-5%, -5%);
    cursor: pointer;
}

.userName {
    word-wrap: normal;
    width: 12vw;
    text-align: right;
    cursor: pointer;
}

.uploadFile {
    margin-top: 15vh;
    display: block;
    justify-content: center;
    align-items: center;
}

.centerText {
    text-align: center;
}

.uploadContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
}
.uploadInput {
    width: auto;
    color: white;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    font-size: 1rem;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.uploadButton {
    margin-left: 2vw;
    width: auto;
    height: auto;
}

.uploadText {
    margin-left: 2vw;
}

.doneButton {
    margin: 4vh 0 2vh 0;
    width: auto;
    height: auto;
    background-color: #09ff07;
    border: none;
}

.deleteContainer {
    display: flex;
    background-color: rgb(145, 145, 143);
    align-items: center;
    justify-content: center;
}

.deleteContainer:hover {
    background-color: rgb(163, 162, 162);
}

.delete {
    color: rgb(43, 43, 42);
    z-index: 100;
}

.delete:hover {
    color: rgb(199, 5, 5);
}

.imageContainer {
    padding: 2vw;
    background-color: rgb(209, 209, 206);
    border: 2px solid black;
    outline: 2vw;
}

.image {
    border: 1px solid black;
    width: 100%;
}

/* Merge PDF */

.mergeText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1rem;
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 33vw;
}

.deleteIcon {
    color: red;
    z-index: 100;
}

.mergeInput {
    width: auto;
}

.clearButton {
    margin: 4vh 0 2vh 28vw;
    width: auto;
    height: auto;
    border: none;
}

.bookmarkCheckBox {
    size: 10px;
    padding: 20px;
    transform: scale(1.5);
}
/* PDF To Image */

.pdfToImage {
    width: 100%;
}

.checkBox {
    width: 2vw;
    height: 3vh;
}

.checkBoxContainer {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkBoxContainer:hover {
    background-color: rgb(163, 162, 162);
}

.checkBoxInput {
    margin: 0vh 1vw 0vh 0vw;
    color: black;
    font-size: 1.1rem;
}

/* Privacy Page  */

.privacyBox {
    margin: 20vh 15vw 20vh 15vh;
}

.privacyHeadings {
    color: yellow;
    margin: 3vh 0vw 3vh 0vw;
    font-size: 1.6rem;
}

.privacyText {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* Footer  */

.footerBox {
    margin-top: 10vh;
    margin-bottom: 0;
    background-color: rgba(219, 217, 217, 0.404);
    border-top: 1px solid rgba(78, 78, 78, 0.356);
    height: 6vh;
    align-items: center;
    justify-content: center;
    display: flex;
}

.footerLink {
    margin: 0vh 2vw 0vh 2vw;
    color: rgba(0, 0, 0, 0.63);
}

.loader {
    display: flex;
    justify-content: center;
    margin-top: 8vh;
}

/* Not Found Page */

.notFoundPage {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(230, 172, 65);
}

.notFoundLink {
    color: orange;
    text-decoration: none;
}

.notFoundLink:hover {
    color: #09ff07;
    text-decoration: underline;
}
